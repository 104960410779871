<template>
 <div>
   <child></child>
 </div>
</template>

<script>
import docuAbout from "@/views/component/docuCommon/docuAbout"
export default {
name: "SmplEditAbout",
components:{
  child:docuAbout,
},
props:{
}
}
</script>

<style scoped>

</style>