<template>
  <el-table :data="smplForm.smpl_mtrl_list" border @selection-change="handleSelectionChange">
    <el-table-column type="selection" width="48" align="center"></el-table-column>
    <el-table-column label="序号" width="48" align="center">
      <template slot-scope="scope">
        {{ scope.$index+1 }}
      </template>
    </el-table-column>
    <el-table-column label="物料编号">
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'smpl_mtrl_list.'+scope.$index+'.mtrl_no'" :rules="[{required:true}]">
          <el-input :disabled="true" v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_no" maxlength="30" show-word-limit placeholder="暂无物料编号"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="物料名称">
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'smpl_mtrl_list.'+scope.$index+'.mtrl_name'" :rules="[{required:true}]">
          <el-input :disabled="true" v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_name" maxlength="30" show-word-limit placeholder="暂无物料名称"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="类型">
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'smpl_mtrl_list.'+scope.$index+'.mtrl_type'" :rules="[{required:true}]">
          <el-select :disabled="true" v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_type" placeholder="请选择物料类型" clearable>
            <el-option
                v-for="item in mtrlTypeGroupOpt"
                :key="item.id"
                :label="item.param1"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="规格" show-word-limit>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'smpl_mtrl_list.'+scope.$index+'.mtrl_spec'">
          <el-tooltip :content="smplForm.smpl_mtrl_list[scope.$index].mtrl_spec" placement="top" v-if="smplForm.smpl_mtrl_list[scope.$index].mtrl_spec">
            <el-input :disabled="true" maxlength="50" v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_spec" placeholder="暂无规格"></el-input>
          </el-tooltip>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="颜色">
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'smpl_mtrl_list.'+scope. $index+'.mtrl_color'">
          <el-input :disabled="true"  v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_color" maxlength="10" show-word-limit placeholder="暂无颜色"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="克重">
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'smpl_mtrl_list.'+scope.$index+'.mtrl_weight'">
          <el-input :disabled="true"  show-word-limit maxlength="8" @blur ="smplForm.smpl_mtrl_list[scope.$index].mtrl_weight = helper.retain(smplForm.smpl_mtrl_list[scope.$index].mtrl_weight,2)" v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_weight" placeholder="暂无克重"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="门幅">
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'smpl_mtrl_list.'+scope.$index+'.mtrl_width'">
          <el-input  :disabled="true" show-word-limit maxlength="6" @blur ="smplForm.smpl_mtrl_list[scope.$index].mtrl_width = helper.retain(smplForm.smpl_mtrl_list[scope.$index].mtrl_width,2)" v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_width" placeholder="暂无门幅"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="单位" >
      <template slot-scope="scope">
        <el-form-item label-width="0"  :prop="'smpl_mtrl_list.'+scope.$index+'.mtrl_unit'" :rules="[{required:true}]">
          <el-select :disabled="true" v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_unit" placeholder="请选择单位"  clearable>
            <el-option
              v-for="item in smplTypeGroupOpt1"
              :key="item.id"
              :label="item.param1"
              :value="item.param1">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">耗料</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'smpl_mtrl_list.'+scope.$index+'.prod_mtrl_consume'" :rules="[{required:true}]">
          <el-input  show-word-limit maxlength="8" @blur ="smplForm.smpl_mtrl_list[scope.$index].prod_mtrl_consume = helper.retain1(smplForm.smpl_mtrl_list[scope.$index].prod_mtrl_consume,2,10000)" v-model="smplForm.smpl_mtrl_list[scope.$index].prod_mtrl_consume" placeholder="暂无耗料"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {get,post} from "@api/request";
import {optnAPI} from "@api/modules/optn";

export default {
  name:"SmplEditBomChild",
  props:{
    smplForm:{
      type: Object,
      required:true,
    }
  },
  data(){
    return {
      selectionsList:[],
      smplTypeGroupOpt1: [],
      mtrlTypeGroupOpt:[],
    }
  },
  created(){
    this.initData()
  },
  methods:{
    initData(){
      this.getMtrlType()
      this.getMtrlType1()
    },
    // 获取面料类型
    getMtrlType(flag){
      get(optnAPI.getAllContent,{perm_id : 10004})
      .then(res=>{
        if(res.data.code === 0) {
          this.mtrlTypeGroupOpt = res.data.data
          console.log('this.mtrlTypeGroupOpt',this.mtrlTypeGroupOpt)
        }
      })
    },
    // 获取单位类型
    getMtrlType1(flag){
      get(optnAPI.getAllContent,{perm_id : 10005})
      .then(res=>{
        if(res.data.code === 0) {
          this.smplTypeGroupOpt1 = res.data.data
        }
      })
    },
    //bom清单选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection
      this.$emit("handleSelectionChange",this.selectionsList)
      console.log('selection',this.selectionsList)
    },
  },
}
</script>

<style scoped>

</style>
