<template>
  <div class="vg_wrapper">
    <el-tabs
        v-model="activeName"
        type="border-card"
    >
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow"/>
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'" :lazy="true">
        <childTab2 :isShow="isSho"/>
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'" :lazy="true">
        <childTab3/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SmplEditMain from "@/views/DevelopManagement/SmplManage/TabChild/SmplEditMain";
import SmplEditAppr from "@/views/DevelopManagement/SmplManage/TabChild/SmplEditAppr";
import SmplEditAbout from "@/views/DevelopManagement/SmplManage/TabChild/SmplEditAbout";
export default {
  name: 'AcctEdit',
  components: {
    childTab1: SmplEditMain,
    childTab2: SmplEditAppr,
    childTab3: SmplEditAbout,
  },
  data() {
    return {
      activeName: "first",
      isSho:true,
    }
  },
  created() {},
  methods: {
    isShow(val){
      this.isSho = val;
    }
  }
};
</script>

<style scoped lang="scss">

</style>