<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMtrlsNow()">
        <el-row>
          <el-col :md="7">
            <el-form-item label="物料编号">
              <el-input size="small" v-model.trim="searchForm.mtrl_no" clearable placeholder="请填写物料编号编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="7">
            <el-form-item label="物料名称">
              <el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写物料名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="7">
            <el-form-item label="物料类型">
              <el-select size="small" v-model="searchForm.mtrl_type" placeholder="请选择物料类型" clearable >
                <el-option
                    v-for="item in mtrlTypeGroupCrft"
                    :key="item.id"
                    :label="item.param1"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="3">
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getMtrlsNow()" class="vg_ml_16">查询</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row class="vd_mar15">
      <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
      <el-button size="mini" type="primary" @click="confirmIn()">确认选择</el-button>
    </el-row>
    <el-row class="vd_mrt">
      <el-col :md="24">
        <el-table ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" :row-key="getRowKey"  border v-loading="loading">
          <el-table-column type="selection" width="48"  align="center" :reserve-selection="true"/>
          <el-table-column label="物料编号" prop="mtrl_no"/>
          <el-table-column label="物料名称" prop="mtrl_name" />
          <!-- <el-table-column label="规格" prop="mtrl_spec"   /> -->
          <el-table-column label="类型" prop="mtrl_type"  :formatter="formatLeavType" />
          <!-- <el-table-column label="颜色" prop="mtrl_color"  />
          <el-table-column label="门幅" prop="mtrl_width" /> -->
          <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" />
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {mtrlAPI} from "@api/modules/mtrl"
import pubPagination from "@/components/common/pubPagination";
import {optnAPI} from "@api/modules/optn";

export default {
  name: "SmplEditBom",
  components:{
    pubPagination
  },
  data() {
    return {
      tableData:[],
      searchForm:{
        mtrl_no:'',
        mtrl_name:'',
        mtrl_type:'',
        mtrl_color:'',
        timeValue:{
          startTime:null,
          endTime:null
        },
      },
      totalPage:0,
      btn:{},
      loading: true,
      multiSelection: [],
      mtrlTypeGroupCrft:[],
      currentPage:1,
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData(){
      this.getMtrlsList()
      this.getMtrlType()
    },
    // 获取面料类型
    getMtrlType(flag){
      get(optnAPI.getAllContent,{perm_id : 10004})
      .then(res=>{
        if(res.data.code === 0) {
          this.mtrlTypeGroupCrft = res.data.data
          console.log('this.mtrlTypeGroupOpt',this.mtrlTypeGroupOpt)
        }
      })
    },
    // 获取物料信息
    getMtrlsList(){
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      const searchBody = this.searchForm;
      searchBody.start_time = timeNewVal.startTime;
      searchBody.end_time = timeNewVal.startTime;
      searchBody.page_no=this.currentPage;
      searchBody.status=2;
      get(mtrlAPI.getMtrls,searchBody)
      .then(res=>{
        if(res.data.code === 0){
          this.loading = false;
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })

    },
    // 清空
    clear(){
      this.$refs.multiTable.clearSelection();
    },
    // 查询方法
    getMtrlsNow(){
      this.currentPage = 1
      this.getMtrlsList()
    },
    // 指定一个key标识这一行的数据
    getRowKey (row) {
      return row.mtrl_id;
    },
    // 多选
    handleSelectionChange(val){
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.getMtrlsList()
    },
    // 物料类型
    formatLeavType(row){
      if(row.mtrl_type === 1){
        return '面料'
      } else if(row.mtrl_type === 2){
        return '非面料'
      } 
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDay(row.create_time)
    },
    // 取消选择
    cancel(){
      this.clear()
    },
    // 确认选择
    confirmIn(){
      this.$emit("childBom",this.multiSelection)
    },
    // 选未选中提示
    doDelete(){
      if(this.multiSelection.length === 0){
        this.$message({type:'warning',message:'请至少选择一条数据！'})
      } else {
        this.mBox()
      }
    },
  }
}
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_mrt{
  margin-top: 20px;
}
.vd_mar15{
  margin: 15px 0;
}
</style>
