<template>
  <div class="vg_wrapper vd_top">
    <div class="vd_edhea">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="smplForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('smplForm')"/> 
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
      <el-button type="primary" :disabled="isDisable" size="small" class="vd_exports" @click="takee()" v-if="isShow">生成产品信息</el-button>
    </div>
    <el-form ref="smplForm" :model="smplForm" label-width="120px" size="mini" :disabled="isShow" :rules="rules">
      <el-row class="vd_buttom">
        <el-col :md="8">
          <el-form-item label="单据编号" prop="smpl_no" class="vd_mrb">
            <el-input v-model="smplForm.smpl_no" show-word-limit placeholder="请填写单据编号"></el-input>
          </el-form-item>
          <el-form-item label="产品货号" prop="prod_no">
            <el-input v-model="smplForm.prod_no" show-word-limit maxlength="10" placeholder="请填写产品货号">
              <el-link slot="append" type="primary" @click="notice = true;" :disabled="isShow">导入</el-link>
            </el-input>
          </el-form-item>
          <el-form-item label="委托单号" prop="smpl_ask_no">
            <el-input v-model="smplForm.smpl_ask_no" maxlength="30" show-word-limit placeholder="请填写委托单号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="打样数量" prop="smpl_num">
            <el-input v-model="smplForm.smpl_num" maxlength="10" @input ="smplForm.smpl_num = helper.keepTNum(smplForm.smpl_num)" show-word-limit placeholder="请填写打样数量"></el-input>
          </el-form-item>
          <el-form-item label="产品名称" prop="prod_name">
            <el-input v-model="smplForm.prod_name" maxlength="50" show-word-limit placeholder="请填写产品名称"></el-input>
          </el-form-item>
          <el-form-item label="产品规格" prop="prod_spec">
            <el-input v-model="smplForm.prod_spec" maxlength="30" show-word-limit placeholder="请填写产品规格"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="交付日期" prop="smpl_deadline">
            <el-date-picker
              v-model="smplForm.smpl_deadline"
              type="date"
              placeholder="选择录入日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="产品类型" prop="prod_type">
            <el-select v-model="smplForm.prod_type" placeholder="请选择产品类型" clearable>
              <el-option
                  v-for="item in smplTypeGroupOpt"
                  :key="item.id"
                  :label="item.param1"
                  :value="item.param1">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="smpl_remark">
            <el-input type="textarea" autosize v-model="smplForm.smpl_remark" maxlength="255" show-word-limit placeholder="请填写备注"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-dialog
        :title="title"
        :visible.sync="notice"
        width="70%">
        <SmplEditIn @confirmIn="confirmIn" @smplImport="smplImport" ref="SmplEditIn"></SmplEditIn>
      </el-dialog>
      <el-tabs v-model="activeName" class="vd_eltab" v-if="exhibit" :disabled="true">
        <el-tab-pane label="BOM清单" name="first" :key="'first'" >
          <div class="vd_but">
            <el-button type="primary" plain size="small" @click="addRow()">导入</el-button>
            <el-button type="danger" plain size="small" @click="delBom()">删除</el-button>
          </div>
          <SmplEditBomChild :smplForm="smplForm" @handleSelectionChange="handleSelectionChange"></SmplEditBomChild>  
        </el-tab-pane>
        <el-dialog
          :title="title"
          :visible.sync="dialogVisible"
          width="70%">
          <SmplEditBom @childBom="childBom" ref="SmplEditBom"></SmplEditBom>
        </el-dialog>
        <el-tab-pane label="工艺步骤" name="second" :key="'second'">
          <div class="vd_but">
            <el-button type="primary" plain size="small" @click="addPrcs()">添加</el-button>
            <el-button type="danger" plain size="small" @click="delPrcs()">删除</el-button>
            <span class="vd_ml10">
              <el-button type="success" plain size="small" @click="dropAble($event)" v-if="!drop">拖拽</el-button>
            </span>
           <span>
             <el-button type="info" plain size="small" @click="cancleDrop($event)" v-if="drop" >关闭拖拽</el-button>
           </span>
          </div>
          <SmplEditPrcsChild v-if="flag" :drop="drop" @rowDrop="rowDrop"  @port="port" @changeInd="changeInd" @setTime="setTime" :smplForm="smplForm" :options="options" :optionc="optionc" :selelct="selelct" :isShow="isShow" @handleSelectionChange1="handleSelectionChange1"></SmplEditPrcsChild>
        </el-tab-pane>
        <el-dialog
          :title="title"
          :visible.sync="dialogVisible1"
          width="70%">
          <SmplEditPrcs @childPrcs="childPrcs" ref="SmplEditPrcs"></SmplEditPrcs>
        </el-dialog>
      </el-tabs>
      <el-row class="vg_mt_16 vd_bortop">
       <el-col>
         <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="smplForm.stff_name"></inputUser>
       </el-col>
     </el-row>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {smplAPI} from "@api/modules/smpl";
import {prodAPI} from "@api/modules/prod";
import editHeader from "@/views/component/editHeader";
import {optnAPI} from "@api/modules/optn";
import inputUser from "@/views/component/inputUser";
import SmplEditBom from "@/views/DevelopManagement/SmplManage/TabChild/Componet/SmplEditBom";
import SmplEditPrcs from "@/views/DevelopManagement/SmplManage/TabChild/Componet/SmplEditPrcs";
import SmplEditIn from "@/views/DevelopManagement/SmplManage/TabChild/Componet/SmplEditIn";
import SmplEditBomChild from "@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditBomChild";
import SmplEditPrcsChild from "@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditPrcsChild";

export default {
  name: 'smplEditMain',
  components: {
    editHeader,
    inputUser,
    SmplEditBom,
    SmplEditPrcs,
    SmplEditIn,
    SmplEditBomChild,
    SmplEditPrcsChild,
  },
  data() {
    return {
      smplForm:{
        prod_no: '',
        prod_name: '',
        smpl_no: '',
        prod_type: '',
        smpl_num: '',
        smpl_deadline: '',
        prod_spec:'',
        smpl_mtrl_list: [],
        smpl_prcs_list: [],
        status:0,
        key:0,
      },
      rules:{
        smpl_num:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        smpl_deadline:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        prod_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        prod_name:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
      },
      smpl_id: '',
      dialogVisible: false,
      dialogVisible1: false,
      mtrlTypeGroupOpt: [],
      smpl_mtrl_list1: [],
      smpl_prcs_list1: [],
      smpl_mtrl_li: [],
      smpl_prcs_li: [],
      activeName: "first",
      key: 0,
      key1: 0,
      isShow: true,
      exhibit: true,
      btn:{},
      smplTypeGroupOpt: [],
      options: [],
      optionc: [],
      selectionsList: [],
      selectionsList1: [],
      index:0,
      selelct: [],
      arr: [],
      secon:1,
      inter:null,
      notice:false,
      smplEditIn:{},
      flag:true,
      isDisable:true,
      drop:false,
      prodNo:null,
      title:'',
      stffForm:{
        stff_name:'',
        cptt_name:'',
        dept_name:'',
        dept_team_name:'',
        stff_id:Number,
        cptt_id:Number,
        dept_id:Number,
        dept_team_id:Number,
      },
    }
  },
  created() {
    this.initData()
  },
  watch: {
  },
  methods:{
    initData(){
      this.getsmplInfo();
      this.getSmplType();
    },
    // 获取样品信息
    getProdsList() {
      get(prodAPI.getProds,
        {
          page_no: 1,
        })
        .then(res => {
          if(res.data.code === 0){
            let prodForm = res.data.data.list;
            prodForm.filter(item=>{
              if(item.prod_id === this.smplForm.prod_id){
                this.prodNo = item.prod_no;
              }
            })
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          }
        })
        .catch(res=>{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        })
    },
    // 获取玩具类型
    getSmplType(flag){
      get(optnAPI.getAllContent,{perm_id : 10003})
      .then(res=>{
        if(res.data.code === 0) {
          this.smplTypeGroupOpt = res.data.data
        }
      })
    },
    //启用编辑
    openEdit(){
      this.isShow = false;
      this.$emit('isShow',this.isShow)
      this.getSelect()
    },
    //撤销编辑
    closeEdit(){
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.initData()
        this.isShow = true;
        this.$emit('isShow',this.isShow)
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      })
    },
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      })
    },
    rowDrop(val){
      this.flag = false;
      this.smpl_prcs_list1 = val;
      for(let i=0;i<this.smpl_prcs_list1.length;i++){
        let arr = [];
        let arr1 = [];
        arr = this.smpl_prcs_list1[i].prod_prcs_part_in.split(',');
        arr1 = this.smpl_prcs_list1[i].prod_prcs_part_out.split(',')
        this.options[i] = arr;
        this.optionc[i] = arr1;
        this.options[i] = this.options[i].filter(item => item && item.trim());
        this.optionc[i] = this.optionc[i].filter(item => item && item.trim())
      }
      this.$nextTick(() => {
        this.flag = true;
      });
    },
    // 保存
    saveInfo() {
      let smplForm =  {};
      smplForm = Object.assign(smplForm,this.smplForm);
      smplForm.smpl_deadline = parseInt(smplForm.smpl_deadline/1000);
      smplForm.smpl_prcs_list = this.smpl_prcs_list1;
      this.smpl_prcs_list1.forEach((item,index)=>{
        item.prod_prcs_sort = index
      })
      smplForm.smpl_mtrl_list = this.smpl_mtrl_list1;
      post(smplAPI.editSmpl,smplForm)
      .then(res => {                                                     
        if(res.data.code === 0){
          this.$message({
            type:'success',
            message:'保存成功'
          })
          this.isShow = true;
          this.$emit('isShow',this.isShow)
          this.initData()
          this.cancleDrop()
        } else if(res.data.code === 7){
          this.$message({
            type:'error',
            message:'单据编号重复'
          })
          smplForm.smpl_deadline = parseInt(smplForm.smpl_deadline*1000);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
          smplForm.smpl_deadline = parseInt(smplForm.smpl_deadline*1000);
          this.initData()
        }
      })
    },
    // 刷新按钮
    buttonRefresh(){
      this.initData()
    },
    // 生成产品信息
    takee(){
      let smplForm = this.smplForm;
      if(smplForm.prod_id >= 0){
        if(this.prodNo === smplForm.prod_no){
            this.$confirm('已有该产品，是否覆盖?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          .then(() => {
            this.takeModiy()
          })
          .catch(()=>{
            this.$message({
              type:'info',
              message:'已取消覆盖'
            })
          })
        }else{
          this.$confirm('是否生成?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          .then(() => {
            this.takeInfo()
            })
          .catch(()=>{
            this.$message({
              type:'info',
              message:'已取消生成'
            })
          })
        }
      }
    },
    // 修改产品的api调用
      takeModiy() {
        let prodForm = {};
        prodForm.prod_id = this.smplForm.prod_id;
        prodForm.prod_no = this.smplForm.prod_no;
        prodForm.prod_name = this.smplForm.prod_name;
        prodForm.prod_spec = this.smplForm.prod_spec;
        prodForm.prod_type = this.smplForm.prod_type;
        prodForm.status = 0;
        prodForm.prod_prcs_list = this.smplForm.smpl_prcs_list;
        prodForm.prod_mtrl_list = this.smplForm.smpl_mtrl_list;
        prodForm.smpl_id = this.$route.query.form_id;
        prodForm.acct_id = this.smplForm.acct_id;
        prodForm.cover_flag = 1;
        post(prodAPI.editProd,prodForm)
        .then(res => {                                                     
          if(res.data.code === 0){
            this.$message({
              type:'success',
              message:'覆盖成功'
            })
            this.initData()
          }
        })
        .catch(()=>{
          this.$message({
            type:'warning',
            message:'产品货号已存在，请重新修改'
          })
        })
      },
    // 添加产品的api调用
    takeInfo() {
      let prodForm = {}
      prodForm.smpl_id = this.$route.query.form_id;
      prodForm.prod_no = this.smplForm.prod_no;
      prodForm.prod_name = this.smplForm.prod_name;
      prodForm.prod_spec = this.smplForm.prod_spec;
      prodForm.prod_type = this.smplForm.prod_type;
      prodForm.prod_mtrl_list = this.smplForm.smpl_mtrl_list;
      prodForm.prod_prcs_list = this.smplForm.smpl_prcs_list;
      prodForm.status = 0;
      prodForm.user_id = this.smplForm.user_id;
      prodForm.dept_id = this.smplForm.dept_id;
      prodForm.stff_id = this.smplForm.stff_id;
      post(prodAPI.addProd,prodForm)
      .then(res => {                                                    
        if(res.data.code === 0){
          this.$message({
            type:'success',
            message:'生成成功'
          })
          this.initData()
        }
      })
    },
    // 导入取消选择
    smplImport(){
      this.notice = false;
    },
    // 导入选择确定
    confirmIn(val){
      this.smplEditIn = val;
      this.notice = false;
      if(this.smplEditIn.prod_no === this.smplForm.prod_no){
        this.$confirm('是否覆盖?', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning'
        })
        .then(() => {
          this.$message({
            type: 'info',
            message: '已覆盖成功'
          })
          this.assignment()
        })
        .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消覆盖'
            })
          })
      }else{
        this.assignment()
      }
      this.$refs.SmplEditIn.clear()
    },
    // 导入赋值
    assignment(){
      this.smplForm.prod_id = this.smplEditIn.prod_id;
      this.smplForm.prod_name = this.smplEditIn.prod_name;
      this.smplForm.prod_no = this.smplEditIn.prod_no;
      this.smplForm.prod_spec = this.smplEditIn.prod_spec;
      this.smplForm.prod_type = this.smplEditIn.prod_type;
      this.smplForm.smpl_mtrl_list = [];
      this.smplForm.smpl_prcs_list = [];
    },
    // prcs出料和进料改为字符串
    changeInd(scope,num) {
      if(num === 1){
        let opt = this.options[scope.$index];
        let arr = [];
        opt.forEach(item=>{
          for (let i=0;i<this.selelct.length;i++){
            if(item === this.selelct[i].value ){
              break;
            }else{
            arr.unshift(item)
            }
          }
        })
        for (let i=0;i<arr.length;i++){
          if(arr[i] !== ' '){
            let temp = {};
            temp.value = arr[i];
            temp.label = arr[i];
            this.selelct.push(temp);
          }
        }
        let notHas = (arr,value) =>{
        let narr = arr.filter((item) =>{
          return item.value == value;
        });
          return narr.length == 0 ? true : false;
        };
        let newArr = [];
        for(let i=0;i<this.selelct.length;i++){
          if(notHas(newArr,this.selelct[i].value)){
            newArr.push(this.selelct[i]);
          }
        }
        this.selelct = newArr;
        this.smplForm.smpl_prcs_list[scope.$index].prod_prcs_part_in = this.options[scope.row.k].join(',');
      }else if(num === 2){
        let opt = this.optionc[scope.$index];
        let arr = [];
        opt.forEach(item=>{
          for (let i=0;i<this.selelct.length;i++){
            if(item === this.selelct[i].value){
              break;
            }else{
            arr.unshift(item)
            }
          }
        })
        for (let i=0;i<arr.length;i++){
          if(arr[i] !== ' '){
            let temp = {};
            temp.value = arr[i];
            temp.label = arr[i];
            this.selelct.push(temp);
          }
        }
        let notHas = (arr,value) =>{
        let narr = arr.filter((item) =>{
          return item.value == value;
        });
          return narr.length == 0 ? true : false;
        };
        let newArr = [];
        for(let i=0;i<this.selelct.length;i++){
          if(notHas(newArr,this.selelct[i].value)){
            newArr.push(this.selelct[i]);
          }
        }
        this.selelct = newArr;
        this.smplForm.smpl_prcs_list[scope.$index].prod_prcs_part_out = this.optionc[scope.row.k].join(',');
      }
      for(let i in this.selelct){
        if(this.selelct[i].value === undefined){
          this.selelct.splice(i,1)
        }
      }
    },
    // prcs按enter计时
    setTime(val){
      this.flag = false;
      this.smplForm.smpl_prcs_list = val;
      this.$nextTick(() => {
        this.flag = true;
      });
    },
    // prcs步骤新增
    addPrcs() {
      this.flag = false;
      let item = {
        k: this.key,
        prod_prcs_sort: '',
        prod_prcs_name: '',
        prod_prcs_crft_names: '',
        prod_prcs_second:'',
        prod_prcs_part_in: '',
        prod_prcs_part_out: '',
        destroy_flag: 0,
      }
      item.prod_prcs_sort = this.key;
      this.smplForm.smpl_prcs_list.push(item);
      this.smpl_prcs_list1 = this.smplForm.smpl_prcs_list.concat();
      this.selectionsList1 = [];
      let temp = [];
      this.options[this.key] = temp;
      this.optionc[this.key] = temp;
      this.key = this.key +1;
      this.$nextTick(() => {
        this.flag = true;
      });
    },
    // prcs步骤删除
    delPrcs() {
      this.flag = false;
      let arr = [];
      for(let i = 0 ; i < this.selectionsList1.length; i++) {
        this.smplForm.smpl_prcs_list.forEach((item)=>{
          if(item.k === this.selectionsList1[i].k) {
            let currentIndex = this.smplForm.smpl_prcs_list.indexOf(item);
            arr.push(currentIndex)
          }
        });
      }
      arr = arr.sort((a,b)=>{return b-a})
      for (let i of arr){
        if(this.smpl_prcs_list1[i].smpl_prcs_id){
          this.smpl_prcs_list1[i].destroy_flag = 1;
        }else{
          this.smpl_prcs_list1.splice(i,1);
        }
        this.smplForm.smpl_prcs_list.splice(i,1);
        this.optionc.splice(i,1)
        this.options.splice(i,1)
      }
      
      for (let a = 0;a < this.smplForm.smpl_prcs_list.length;a++){
        this.smplForm.smpl_prcs_list[a].k = a;
        this.smplForm.smpl_prcs_list[a].prod_prcs_sort = a;
        this.smpl_prcs_list1[a].prod_prcs_sort = a;
      }
      this.key = this.smplForm.smpl_prcs_list.length;
      this.$nextTick(() => {
        this.flag = true;
      });
    },
    // prcs导入按钮
    port(scope){
      this.dialogVisible1 = true;
      this.index = scope.row.k;
    },  
    // prcs清单小窗口确定
    childPrcs(val) {
      this.smpl_prcs_li = val;
      this.dialogVisible1 = false;
      this.flag = false;
      let temp = this.smplForm.smpl_prcs_list[this.index].prod_prcs_crft_names;
      this.smpl_prcs_li.forEach(item=>{
        temp = temp+','+item.crft_name;
      })
      temp = temp.replace(/^(\s|,)+|(\s|,)+$/g, '')
      this.smplForm.smpl_prcs_list[this.index].prod_prcs_crft_names = temp;
      this.$refs.SmplEditPrcs.clearSelect()
      this.$nextTick(() => {
        this.flag = true;
      });
    },
    // prcs行拖拽
    dropAble(){
      this.flag = false;
      this.drop = true;
      this.$nextTick(() => {
        this.flag = true;
      });
    },
    //取消拖拽
    cancleDrop(){
      this.flag = false;
      this.drop = false;
      this.$nextTick(() => {
        this.flag = true;
      });
    },
    // bom清单小窗口确定
    childBom(val) {
      this.smpl_mtrl_li = val;
      this.smpl_mtrl_li = this.smpl_mtrl_li.filter(item => this.smplForm.smpl_mtrl_list.every(item1 => item.mtrl_id != item1.mtrl_id));
      for (let i in this.smpl_mtrl_li){
        this.smpl_mtrl_li[i].k = this.key1;
        this.key1 = this.key1+1
      }
      this.smplForm.smpl_mtrl_list = this.smplForm.smpl_mtrl_list.concat(this.smpl_mtrl_li) 
      this.smpl_mtrl_list1 = this.smplForm.smpl_mtrl_list;
      let smpl_mtrl_list = this.smplForm.smpl_mtrl_list;
      smpl_mtrl_list = smpl_mtrl_list.filter(item => this.selelct.every(item1 => item1.value != item.mtrl_no));
      smpl_mtrl_list.forEach(item => {
          let temp = {};
          temp.value = item.mtrl_no;
          temp.label = item.mtrl_no;
          this.selelct.push(temp);
      });
      console.log('arr11111',this.smplForm.smpl_mtrl_list);
      this.$refs.SmplEditBom.clear()
      this.dialogVisible = false;
    },
    //bom清单导入
    addRow() {
      this.dialogVisible = true;
    },
    //bom清单多选删除
    delBom() {
      let arr = [];
      for(let i = 0 ; i < this.selectionsList.length; i++) {
        this.smplForm.smpl_mtrl_list.forEach((item)=>{
          if(item.k === this.selectionsList[i].k) {
            let currentIndex = this.smplForm.smpl_mtrl_list.indexOf(item);
            arr.push(currentIndex)
            if(item.smpl_mtrl_id){
              this.smpl_mtrl_list1[currentIndex].destroy_flag = 1;
            }else{
              // this.smpl_mtrl_list1.splice(currentIndex,1);
            }
          }
        });
      }
      arr = arr.sort((a,b)=>{return b-a})
      for (let i of arr){
         this.smplForm.smpl_mtrl_list.splice(i,1);
         console.log('i',i,this.smplForm.smpl_mtrl_list);
      }
      for (let a = 0;a < this.smplForm.smpl_mtrl_list.length;a++){
        this.smplForm.smpl_mtrl_list[a].k = a;
      }
      this.key1 = this.smplForm.smpl_mtrl_list.length;
    },
    //bom清单选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection
    },
    //个人步骤选择框  
    handleSelectionChange1(selection) {
      this.selectionsLis1 = [];
      this.selectionsList1 = selection;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 初始化选择框
    getSelect(){
      let smpl_mtrl_list = this.smplForm.smpl_mtrl_list;
      smpl_mtrl_list = smpl_mtrl_list.filter(item => this.selelct.every(item1 => item1.value != item.mtrl_no));
      smpl_mtrl_list.forEach(item => {
          let temp = {};
          temp.value = item.mtrl_no;
          temp.label = item.mtrl_no;
          this.selelct.push(temp);
      });
      let smpl_prcs_list = [];
      let str1 = [];
      let str2 = [];
      if(this.smplForm.smpl_prcs_list){
        this.smplForm.smpl_prcs_list.forEach(item=>{
          str1 = str1.concat(item.prod_prcs_part_in.split(','));
          str2 = str2.concat(item.prod_prcs_part_out.split(','));
        })
      }
      str1 = str1.filter(item=>item && item.trim())
      str2 = str2.filter(item=>item && item.trim())
      for(let i=0;i<str1.length;i++){
        let temp = {};
        temp.value = str1[i];
        temp.label = str1[i];
        smpl_prcs_list.push(temp);
      }
      for(let i=0;i<str2.length;i++){
        let temp = {};
        temp.value = str2[i];
        temp.label = str2[i];
        smpl_prcs_list.push(temp);
      }
      smpl_prcs_list = smpl_prcs_list.filter(item => this.selelct.every(item1 => item1.value != item.value));
      smpl_prcs_list.forEach(item => {
          let temp = {};
          temp.value = item.value;
          temp.label = item.value;
          this.selelct.push(temp);
      });
      // 去除选择框重复
      let notHas = (arr,value) =>{
        let narr = arr.filter((item) =>{
          return item.value === value;
        });
        return narr.length == 0 ? true : false;
      };
      let newArr = [];
      for(let i=0;i<this.selelct.length;i++){
        if(notHas(newArr,this.selelct[i].value)){
          newArr.push(this.selelct[i]);
        }
      }
      this.selelct = newArr;
      console.log('9999',this.selelct)
    },
    // 获取表单信息
    getsmplInfo() {
      this.smplForm.smpl_id = this.$route.query.form_id;
      get(smplAPI.getSmplById,{smpl_id:this.smplForm.smpl_id})
      .then(res=>{
        if (res.data.code === 0) {
          this.key = 0;
          this.key1 = 0;
          this.smplForm = res.data.data.form;
          setTimeout(()=>{
            this.getProdsList();
          },100)
          this.btn = res.data.data.btn;
          // this.bus.$emit('btnEdit',this.btn)
          if(this.smplForm.status === 2){
            this.isDisable = false;
          }
          if(!this.btn.edit){
            this.exhibit = false;
          }
          this.smplForm.smpl_mtrl_list.forEach(item=>{
            item.smpl_id = this.smplForm.smpl_id;
            item.k = this.key1;
            this.key1++;
          })
          this.smplForm.smpl_prcs_list.forEach(item=>{
            let str1 = item.prod_prcs_part_out.split(',');
            let str = item.prod_prcs_part_in.split(',');
            let arr = [];
            if (item.prod_prcs_part_in !== ''){
              let arr1 = [];
              for(let i=0;i<str.length;i++){
                if(arr1.indexOf(str[i]) === -1){
                  arr1.push(str[i])
                }
              }
              this.options.push(arr1);
            }else{
              this.options.push(arr);
            }
            if (item.prod_prcs_part_out !== ''){
              let arr1 = [];
              for(let i=0;i<str1.length;i++){
                if(arr1.indexOf(str1[i]) === -1){
                  arr1.push(str1[i])
                }
              }
              this.optionc.push(arr1);
            }else{
              this.optionc.push(arr);
            }
            item.k = this.key;
            item.smpl_id = this.smplForm.smpl_id;
            this.key++;
          })
          this.smplForm.smpl_deadline = parseInt(this.smplForm.smpl_deadline)*1000;
          this.smpl_mtrl_list1 = this.smplForm.smpl_mtrl_list.concat();
          this.smpl_prcs_list1 = this.smplForm.smpl_prcs_list.concat();
          this.getInputUser()
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    //获取录入人
    getInputUser(){
      this.stffForm.cptt_id = this.smplForm.cptt_id;
      this.stffForm.user_id = this.smplForm.user_id;
      this.stffForm.dept_id = this.smplForm.dept_id;
      this.stffForm.stff_id = this.smplForm.stff_id;
      this.stffForm.dept_name = this.smplForm.dept_name;
      this.stffForm.dept_team_id = this.smplForm.dept_team_id;
      this.stffForm.dept_team_name = this.smplForm.dept_team_name;
      this.stffForm.stff_name = this.smplForm.stff_name;
    },
  }
}
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
.vd_eltab {
  margin-top: 10px;
}
.vd_buttom {
    border-bottom : 1px dashed #C0C4CC;
    padding-bottom: 32px;
}
.vd_but {
  margin-bottom: 10px;
}
.vd_ml10{
  margin-left:10px;
}
.vd_dis {
  display: inline-block;
}
.vd_mrl{
  margin-left: 5px;
}
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
.vd_edhea{
  position: relative;
}
.vd_export{
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_exports{
  position: absolute;
  top: 0;
  left: 120px;
}
.vd_mrb{
  margin-bottom: 1px;
}
</style>
