<template>
  <el-table :data="smplForm.smpl_prcs_list" border @selection-change="handleSelectionChange1" row-key="smpl_prcs_id" class="el-table-two">
    <el-table-column type="selection" width="48" align="center"></el-table-column>
    <el-table-column width="80">
      <template slot="header">
        <span class="vg_deep_red">工艺顺序</span>
      </template>
      <template slot-scope="scope">
          <!-- {{scope.$index+1}} -->
          {{smplForm.smpl_prcs_list[scope.$index].prod_prcs_sort+1}}
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">步骤名称</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'smpl_prcs_list.'+scope.$index+'.prod_prcs_name'" :rules="[{required:true}]">
          <el-input v-model="smplForm.smpl_prcs_list[scope.$index].prod_prcs_name" placeholder="请填写步骤名称"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="工艺描述" width="500">
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'smpl_prcs_list.'+scope.$index+'.prod_prcs_crft_names'">
            <el-tooltip :content="smplForm.smpl_prcs_list[scope.$index].prod_prcs_crft_names" placement="top" v-if="smplForm.smpl_prcs_list[scope.$index].prod_prcs_crft_names">
              <el-input v-model="smplForm.smpl_prcs_list[scope.$index].prod_prcs_crft_names" placeholder="请填写工艺名称">
                <el-link slot="append" type="primary" class="vd_dis vd_mrl" @click="port(scope)" size="small" :disabled="isShow">导入</el-link>
              </el-input>
            </el-tooltip>
            <el-input v-model="smplForm.smpl_prcs_list[scope.$index].prod_prcs_crft_names" placeholder="请填写工艺名称" v-else>
              <el-link slot="append" type="primary" class="vd_dis vd_mrl" @click="port(scope)" size="small" :disabled="isShow">导入</el-link>
            </el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="耗时" width="150">
      <template slot-scope="scope"  >
        <el-form-item label-width="0" :prop="'smpl_prcs_list.'+scope.$index+'.prod_prcs_second'">
          <el-input @keyup.enter.native="setTime(scope)"  v-model="smplForm.smpl_prcs_list[scope.$index].prod_prcs_second" placeholder="请填写耗时时间"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="进料">
      <template slot-scope="scope" >
        <el-form-item label-width="0" class="vd_dis vd_wid100" :prop="'smpl_prcs_list.'+scope.$index+'.prod_prcs_part_in'">
          <el-select
            v-model="options[scope.$index]"
            ref="prcsSel"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="请选择进料名称"
            @change = change(scope,1)>
            <el-option
              v-for="item in selelct"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="出料">
      <template slot-scope="scope" >
        <el-form-item label-width="0" class="vd_dis vd_wid100" :prop="'smpl_prcs_list.'+scope.$index+'.prod_prcs_part_out'">
          <el-select
            v-model="optionc[scope.$index]"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="请选择出料名称"
            @change = change(scope,2)>
            <el-option
              v-for="item in selelct"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <!--    <el-table-column label="排序">-->
    <!--      <template slot-scope="scope">-->
    <!--        <el-button size="mini" icon="el-icon-top"-->
    <!--                   :disabled="scope.$index===0"-->
    <!--                   @click="moveUp(scope.$index,scope.row)">-->
    <!--        </el-button>-->
    <!--        <el-button size="mini" icon="el-icon-bottom"-->
    <!--                   :disabled="scope.$index===(smplForm.smpl_prcs_list.length-1)"-->
    <!--                   @click="moveDown(scope.$index,scope.row)">-->
    <!--        </el-button>-->
    <!--      </template>-->
    <!--    </el-table-column>-->
  </el-table>
</template>

<script>
import options from "@/views/component/common/options";
import Sortable from "sortablejs";
export default {
  name:"SmplEditPrcsChild",
  props:{
    smplForm:{
      type: Object,
      required:true,
    },
    options:{
      type: Array,
      required:true,
    },
    optionc:{
      type: Array,
      required:true,
    },
    selelct:{
      type: Array,
      required:true,
    },
    isShow:{
      type: Boolean,
      required:true,
    },
    drop:{
      type:Boolean,
      required:true,
    }
  },
  data(){
    return {
      selectionsList:[],
      mtrlTypeGroupOpt:[],
      begin: false,
      inter: null,
      flag:true,
    }
  },
  watch:{
    smplForm(val){
    }
  },
  created(){
    this.initData()
  },
  mounted() {
    this.rowDrop();
  },
  methods:{
    initData(){
      this.mtrlTypeGroupOpt = options.mtrlUnit;
    },
    enterNames(row, column, cell, event){
      console.log('111111',row, column, cell, event)
    },
    //prcs清单选择框
    handleSelectionChange1(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit("handleSelectionChange1",this.selectionsList);
    },
    // prcs进料出料选择
    change(scope,num){
      if(num === 1){
        this.options[scope.$index] = this.options[scope.$index].filter(item => item && item.trim())
      }else if(num === 2){
        this.optionc[scope.$index] = this.optionc[scope.$index].filter(item => item && item.trim())
      }
      this.$emit("changeInd", scope,num)
    },
    //prcs记录秒数键盘控制 
    setTime(scope){
      if(!this.begin){
        clearInterval(this.inter)
        let num = this.smplForm.smpl_prcs_list[scope.row.k].prod_prcs_second;
        if(num === undefined){
          num = 0;
        }
        this.inter = setInterval(() => {
          num = parseInt((num+0.1)*100)/100;
          this.smplForm.smpl_prcs_list[scope.row.k].prod_prcs_second = this.helper.retain(num,1);
        }, 100);
        this.begin = true;
      }else{
        clearInterval(this.inter)
        this.$emit("setTime",this.smplForm.smpl_prcs_list)
        this.begin = false;
      }
      
    },
    // prcs导入弹框
    port(scope){
      this.$emit("port",scope)
    },
    //上下键排序
    // moveUp(index,row) {
    //   let that = this;
    //   if (index > 0) {
    //     let upDate = that.smplForm.smpl_prcs_list[index - 1];
    //     that.smplForm.smpl_prcs_list.splice(index - 1, 1);
    //     that.smplForm.smpl_prcs_list.splice(index,0, upDate);
    //   }else {
    //     this.$message.warning('已经是第一条，不可上移');
    //   }
    // },
    // moveDown(index,row){
    //   let that = this;
    //   if ((index + 1) === that.smplForm.smpl_prcs_list.length){
    //     this.$message.warning('已经是最后一条，不可下移');
    //   } else {
    //     let downDate = that.smplForm.smpl_prcs_list[index + 1];
    //     that.smplForm.smpl_prcs_list.splice(index + 1, 1);
    //     that.smplForm.smpl_prcs_list.splice(index,0, downDate);
    //   }
    // },
    //拖拽排序
    rowDrop() {
      if(this.drop){
        const tbody = document.querySelector('.el-table-two .el-table__body-wrapper tbody')
        const _this = this
        Sortable.create(tbody, {
          draggable: ".el-table__row",
          onEnd({ newIndex, oldIndex }) {
            const currRow = _this.smplForm.smpl_prcs_list.splice(oldIndex, 1)[0]
            _this.smplForm.smpl_prcs_list.splice(newIndex, 0, currRow)
            _this.$emit('rowDrop', _this.smplForm.smpl_prcs_list)
          }
        })
      }
    },
  }
}
</script>

<style lang="scss">
.vd_dis {
  display: inline-block;
}
.vd_mrl{
  margin-left: 5px;
}
.vd_wid100{
  width: 100%;
}
</style>